import React, { useRef, useEffect, useState } from "react";
import { useGeneratorContext } from "../../context/GeneratorContext";
import { ThinkhausHeaderTitle } from "../Generator/ThinkhausHeaderTitle";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import './InternalSyndigoUpdate.css';
import { UploadButton } from "../UploadButton/UploadButton";
import { SyndigoSubmitButton } from "../SyndigoSubmitButton/SyndigoSubmitButton";
import upcTemplate from "./TableTemplates/upcTemplate";
import statusTemplate from "./TableTemplates/statusTemplate";
import actionsTemplate from "./TableTemplates/actionsTemplate";
import fieldsTemplate from "./TableTemplates/fieldsTemplate";
import { SyndigoPublishButton } from "../SyndigoPublishButton/SyndigoPublishButton";
import { SearchThinkhausFieldsUpload } from "../SearchThinkhausFieldsUploads/SearchThinkhausFieldsUploads";
import { ExportButton } from "../ExportButton/ExportButton";
import { ModifiersPanel } from "./ModifiersPanel/ModifiersPanel";


const InternalSyndigoUpdate = () => {
    const {
        products, setProducts,
        URL,
        imageOverrides, setImageOverrides,
        publishOverride, setPublishOverride,
        choosingSheet, setChoosingSheet,
        sheetChoices, setSheetChoices,
        wb, setWb,
        sheet, setSheet,
        updateStatus, setUpdateStatus,
        publishStatus, setPublishStatus,
        uploadTableData,
        resultsBrandLibrary, setResultsBrandLibrary,
        setIsShouldFetchResults,
        overrideTemplate, setOverrideTemplate,
        templateOptions, setTemplateOptions
    } = useGeneratorContext();

    const [isTableLoading, setIsTableLoading] = useState(false);

    const toast = React.useRef(null);
    const stepperRef = useRef(null);
    const scrollPosition = useRef(0);

    const itemSize = 48; //px size for datatable rows

    useEffect(() => {
        setIsShouldFetchResults(true); // When the status changes (either update or publish) new results should be fetched

    }, [publishStatus, updateStatus, setIsShouldFetchResults]);

    useEffect(() => {
        if (products.length > 0) {
            // Products loaded, table ready
            setIsTableLoading(false);
        } else {
            // Products not ready yet
            setIsTableLoading(true);
        }
    }, [products]); // Trigger on products array change

    const handleSetImageOverride = (index, value) => {
        const newImageOverrides = [...imageOverrides];
        newImageOverrides[index] = value;
        setImageOverrides(newImageOverrides);
    }

    const handleSetPublishOverride = (index, value) => {
        setPublishOverride([value]);
    }

    const submitAction = (rowData) => {
        return (
            <SyndigoSubmitButton
                products={rowData}
                URL={URL}
                buttonText="Submit"
                imageOverrides={imageOverrides}
                setStatus={setUpdateStatus}
            />
        );
    };

    const publishAction = (rowData) => {
        return (
            <SyndigoPublishButton
                products={rowData}
                URL={URL}
                buttonText={"Publish"}
                imageOverrides={publishOverride}
                setPublishStatus={setPublishStatus}
            />
        )
    }

    // handles scroll for virtual Scroller on DataTable
    const handleScroll = (event) => {
        const target = event.target as HTMLElement;
        const newScrollPosition = target.scrollTop;
        scrollPosition.current = newScrollPosition;
    };

    const UpdateTable = ({ scrollPosition }) => {
        useEffect(() => {
            if (!isTableLoading) {
                setTimeout(() => {
                    const scrollBody = document.querySelector('.p-virtualscroller');
                    if (scrollBody) {
                        scrollBody.scrollTop = scrollPosition.current; // set the scroll position
                    }
                }, 50); // small delay to wait for the table to update before scroll is set
            }
        }, [isTableLoading]); // want to trigger after table shows loading

        return (
            <DataTable
                value={products}
                emptyMessage="Please upload an excel file in Generator to add UPCs or insert manually here."
                className="p-datatable-sm w-full"
                scrollable
                scrollHeight="500px"
                stripedRows
                virtualScrollerOptions={{
                    itemSize: itemSize,
                    delay: 0,
                    showLoader: false,
                    onScroll: handleScroll,
                    lazy: false
                }}
                loading={isTableLoading}
            >
                <Column
                    field="upc"
                    header={<span data-cy-upcheader>UPC</span>}
                    body={(rowData) => upcTemplate(rowData)}
                />
                <Column
                    field="fields"
                    header={<span data-cy-fieldheader>Fields</span>}
                    body={rowData => fieldsTemplate(rowData)}
                />
                <Column
                    field="status"
                    header={<span data-cy-statusheader>Status</span>}
                    body={rowData => statusTemplate(rowData, updateStatus)}
                />
                <Column
                    field="Action"
                    header={<span data-cy-actionheader>Action</span>}
                    body={rowData => actionsTemplate(rowData, products, setProducts, resultsBrandLibrary, setResultsBrandLibrary, updateStatus, setUpdateStatus, publishStatus, setPublishStatus, setIsShouldFetchResults, setIsTableLoading, submitAction(rowData))}
                />
            </DataTable>
        );
    };

    const PublishTable = ({ scrollPosition }) => {
        useEffect(() => {
            if (!isTableLoading) {
                setTimeout(() => {
                    const scrollBody = document.querySelector('.p-virtualscroller');
                    if (scrollBody) {
                        scrollBody.scrollTop = scrollPosition.current; // set the scroll position
                    }
                }, 50); // small delay to wait for the table to update before scroll is set
            }
        }, [isTableLoading]); // want to trigger after table shows loading
        return (
            <DataTable
                value={products}
                emptyMessage="Please upload an excel file in Generator to add UPCs or insert manually here."
                className="p-datatable-sm w-full"
                scrollable
                scrollHeight="500px"
                stripedRows
                virtualScrollerOptions={{
                    itemSize: itemSize,
                    delay: 0,
                    showLoader: false,
                    onScroll: handleScroll,
                    lazy: false
                }}
                loading={isTableLoading}
            >
                <Column
                    field="upc"
                    header={<span data-cy-upcheader>UPC</span>}
                    body={(rowData) => upcTemplate(rowData)}

                />
                <Column
                    field="status"
                    header={<span data-cy-statusheader>Status</span>}
                    body={rowData => statusTemplate(rowData, publishStatus)}
                />
                <Column
                    field="Action"
                    header={<span data-cy-actionheader>Action</span>}
                    body={rowData => actionsTemplate(rowData, products, setProducts, resultsBrandLibrary, setResultsBrandLibrary, updateStatus, setUpdateStatus, publishStatus, setPublishStatus, setIsShouldFetchResults, setIsTableLoading, publishAction(rowData))}
                />
            </DataTable>
        )
    }

    return (
        <div className="container min-w-screen surface-ground">
            <div className="container w-11 min-h-screen mx-auto ">
                <Toast ref={toast} />
                <div className="flex justify-content-between align-items-center mb-5">
                    <ThinkhausHeaderTitle>Syndigo Update - Thinkhaus Fields</ThinkhausHeaderTitle>
                </div>

                <Stepper ref={stepperRef}>
                    <StepperPanel header="Update">
                        <div className="flex flex-column h-auto">
                            {!products.length ?
                                <UploadButton
                                    products={products}
                                    setProducts={setProducts}
                                    setChoosingSheet={setChoosingSheet}
                                    setSheet={setSheet}
                                    setSheetChoices={setSheetChoices}
                                    setWb={setWb}
                                    choosingSheet={choosingSheet}
                                    wb={wb}
                                    sheet={sheet}
                                    sheetChoices={sheetChoices}
                                />
                                :
                                <UpdateTable
                                    scrollPosition={scrollPosition}
                                />
                            }


                        </div>
                        <div className="flex pt-4 justify-content-between">
                            <Button data-testid='updateBack' label="Back" severity="secondary" icon="pi pi-arrow-left" className='p-button-rounded p-2' onClick={() => stepperRef.current.prevCallback()} disabled />
                            {products.length > 0 &&
                                <SyndigoSubmitButton
                                    products={products}
                                    URL={URL}
                                    buttonText='Submit All'
                                    imageOverrides={imageOverrides}
                                    setStatus={setUpdateStatus}
                                />
                            }
                            <Button data-testid='updateNext' label="Next" severity='success' icon="pi pi-arrow-right" iconPos="right" className='p-button-rounded p-2' onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                        <ModifiersPanel
                            imageOverrides={imageOverrides}
                            setImageOverrides={setImageOverrides}
                            handleSetImageOverride={handleSetImageOverride}
                            overrideTemplate={overrideTemplate}
                            setOverrideTemplate={setOverrideTemplate}
                            templateOptions={templateOptions}
                            setTemplateOptions={setTemplateOptions}
                            tooltipText="Selecting 'Default' or 'KEEP EXISTING IMAGE' has the same effect when updating, preserving the currently displayed image in Syndigo."
                        />
                    </StepperPanel>

                    <StepperPanel header="Publish">
                        <div className="flex flex-column h-auto" >
                            {!products.length ?
                                <UploadButton
                                    products={products}
                                    setProducts={setProducts}
                                    setChoosingSheet={setChoosingSheet}
                                    setSheet={setSheet}
                                    setSheetChoices={setSheetChoices}
                                    setWb={setWb}
                                    choosingSheet={choosingSheet}
                                    wb={wb}
                                    sheet={sheet}
                                    sheetChoices={sheetChoices}
                                />
                                :
                                <PublishTable
                                    scrollPosition={scrollPosition}
                                />
                            }
                        </div>
                        <div className="flex pt-4 justify-content-between">
                            <Button data-testid='publishBack' label="Back" severity="secondary" icon="pi pi-arrow-left" className='p-button-rounded p-2' onClick={() => stepperRef.current.prevCallback()} />
                            {products.length > 0 &&
                                <SyndigoPublishButton
                                    products={products}
                                    URL={URL}
                                    buttonText={"Publish All"}
                                    imageOverrides={publishOverride}
                                    setPublishStatus={setPublishStatus}
                                    data-testid="Publish All"
                                />
                            }
                            <Button data-testid='publishNext' label="Next" severity='success' icon="pi pi-arrow-right" iconPos="right" className='p-button-rounded p-2' onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                        <ModifiersPanel
                            imageOverrides={publishOverride}
                            setImageOverrides={setPublishOverride}
                            handleSetImageOverride={handleSetPublishOverride}
                            overrideTemplate={overrideTemplate}
                            setOverrideTemplate={setOverrideTemplate}
                            templateOptions={templateOptions}
                            setTemplateOptions={setTemplateOptions}
                            hideTemplate={true}
                            tooltipText="Select 'KEEP EXISTING IMAGE' to retain the current image when publishing. Choosing a different override will trigger logic specific to Carousel #1."
                        />
                    </StepperPanel>

                    <StepperPanel header="Results">
                        <div className="flex flex-column h-auto">
                            <SearchThinkhausFieldsUpload />
                        </div>
                        <div className="flex pt-4 justify-content-between">
                            <Button label="Back" severity="secondary" icon="pi pi-arrow-left" className='p-button-rounded p-2' onClick={() => stepperRef.current.prevCallback()} />
                            <ExportButton className="p-button-rounded" URL={URL} products={uploadTableData} wordLists={{ bannedWords: [], factCheckWords: [] }} fileName="uploadResults" />
                            <Button label="Next" severity='success' icon="pi pi-arrow-right" iconPos="right" className='p-button-rounded p-2' onClick={() => stepperRef.current.nextCallback()} disabled />
                        </div>
                    </StepperPanel>
                </Stepper>
            </div>
        </div>
    )
}
export default InternalSyndigoUpdate;