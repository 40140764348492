import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { supabaseClient } from '../../config/supabase-client';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import '../Auth/authStuff.css';
import { useAuth } from '../../context/AuthProvider';

const PasswordResetPage = () => {

  const [password, setPassword] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isMatch, setIsMatch] = useState(true);
  const navigate = useNavigate();
  const toastRef = useRef(null);
  const isRecoveryCompleteRef = useRef(false); // useRef persists between remounts, unlike useState

  const { isRecoveryEvent, signOut } = useAuth();

  useEffect(() => {

    if (!isRecoveryEvent) {
      navigate('/'); // redirect to home
    }

    return () => {
      // React.StrictMode renders components twice which would trigger this unmount
      // so we add a check
      if (isRecoveryCompleteRef.current) {
        signOut();
      }
    };
  }, [isRecoveryEvent]);

  const handleToastError = (error: string) => {
    toastRef.current.show({
      severity: 'error',
      summary: 'Could not update password',
      detail: error,
      sticky: 10000
    })
  }

  const accept = async () => {
    try {
      if (password !== passwordRepeat) {
        // alert('Passwords do not match!');
        throw Error('Passwords do not match.');
      }

      const { error } = await supabaseClient.auth.updateUser({ password: password })

      if (error) throw error;

      isRecoveryCompleteRef.current = true;
      alert('Your password has been updated. ');
      navigate('/'); // redirect to home

    } catch (error) {
      console.error(error);
      handleToastError(error.message);
    }
  }

  const reject = () => {
    setIsDialog(false);
  }

  const handlePasswordReset = async () => {
    try {
      if (password !== passwordRepeat) {
        // alert('Passwords do not match!');
        setIsMatch(false);
        throw Error('Passwords do not match.');
      }
      setIsMatch(true);
      setIsDialog(true);

    } catch (error) {
      handleToastError(error.message);
    }
  };

  return (
    <div className='flex-column h-screen flex align-items-center justify-content-center pb-8'>
      <Toast ref={toastRef} />
      <ConfirmDialog
        visible={isDialog}
        header='Are you sure?'
        onHide={() => setIsDialog(false)}
        accept={accept}
        reject={reject}
        resizable={false}
        draggable={false}
        data-testid='confirm-submit-dialog'
      >
      </ConfirmDialog>
      <div className="">
        <h1 className='pb-4'>Reset Your Password</h1>
        <div className="password-text-container">
          <span className="p-float-label mr-2">
            <Password
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              toggleMask
              data-testid="password-input"
            />
            <label htmlFor="password" >New Password</label>
          </span>
          <span className="p-float-label" >
            <Password
              id='password'
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              toggleMask
              feedback={false}
              invalid={!isMatch}
              data-testid="password-input-repeat"
            />
            <label htmlFor="password" >Repeat Password</label>
          </span>
        </div>
        <Button label="Update Password" onClick={handlePasswordReset} data-testid='update-button' />
      </div>
    </div>
  );
};

export default PasswordResetPage;
