import React from "react";
import PasswordResetPage from "./ResetPasswordPage";
import { useAuth } from "../../context/AuthProvider";
import { Navigate } from "react-router-dom";


export const ResetPasswordPageWrapper = () => {
    const { isRecoveryEvent } = useAuth();

    if (!isRecoveryEvent) {
        return <Navigate to="/" replace />; // Redirect to home if recovery event is invalid
    }

    return (
        <PasswordResetPage />
    )
}