import { Button } from "primereact/button";
import { ReactNode } from "react";
import { handleRemoveUPC } from "../InternalSyndigoUpdateFuncs";

const actionsTemplate = (rowData, products, setProducts, resultsBrandLibrary, setResultsBrandLibrary, updateStatus, setUpdateStatus, publishStatus, setPublishStatus, setIsShouldFetchResults, setIsTableLoading, children: ReactNode) => {

    return (
        <div className="flex align-items-center justify-content-between">
            {children}
            <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text"
                onClick={() => handleRemoveUPC(rowData, products, setProducts, resultsBrandLibrary, setResultsBrandLibrary, updateStatus, setUpdateStatus, publishStatus, setPublishStatus, setIsShouldFetchResults, setIsTableLoading)}
                data-cy-removeupc
            />
        </div>
    );
};

export default actionsTemplate;
