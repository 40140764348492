import { Dropdown } from "primereact/dropdown";
import { forwardRef } from "react";

type CommoditySelectProps = {
    imageOverride: string,
    setImageOverride: (value: string) => void,
    dataTestid: string,
    addValue?: [{ label, value }] // This is for adding an extra option to the list
};

export const ImageOverrideDropdown = forwardRef<Dropdown, CommoditySelectProps>(({
    imageOverride,
    setImageOverride,
    dataTestid,
    addValue = null,
}: CommoditySelectProps, ref) => {

    const imageOverrideValues = [
        { label: 'Default', value: 'Default' },
        { label: 'CLEAR IMAGE DATA', value: 'CLEAR IMAGE DATA' },
        { label: 'Ecommerce', value: 'Ecommerce' },
        { label: 'Planogram Straight on Back Shot Image', value: 'Planogram Straight on Back Shot Image' },
        { label: 'Planogram Straight on Front Shot Image', value: 'Planogram Straight on Front Shot Image' },
        { label: 'Planogram Straight on Bottom View Image', value: 'Planogram Straight on Bottom View Image' },
        { label: 'Planogram Straight on Top View Image', value: 'Planogram Straight on Top View Image' },
        { label: 'Planogram Straight on Left View Image', value: 'Planogram Straight on Left View Image' },
        { label: 'Planogram Straight on Right View Image', value: 'Planogram Straight on Right View Image' },
        { label: 'Front - Center - Elevated', value: 'Front - Center - Elevated' },
        { label: 'Front - Right - Elevated', value: 'Front - Right - Elevated' },
        { label: 'Front - Left - Elevated', value: 'Front - Left - Elevated' },
        { label: 'Nutrition Facts Label Image', value: 'Nutrition Facts Label Image' },
        { label: 'Nutrition Ingredients Image', value: 'Nutrition Ingredients Image' },
        { label: 'Main Product Image', value: 'Main Product Image' },
        { label: 'Supplement Facts Label Image', value: 'Supplement Facts Label Image'},
        { label: 'Detailed Product View 1', value: 'Detailed Product View 1' },
        { label: 'Detailed Product View 2', value: 'Detailed Product View 2' },
        { label: 'Detailed Product View 3', value: 'Detailed Product View 3' },
        { label: 'Detailed Product View 4', value: 'Detailed Product View 4' },
        { label: 'Detailed Product View 5', value: 'Detailed Product View 5' },
        { label: 'Detailed Product View 6', value: 'Detailed Product View 6' }
    ]

    const handleImageSelect = (e: any) => {
        setImageOverride(e.value);
    }

    return (
        <Dropdown
            className="ml-1 mb-1 my-0.5"
            value={imageOverride}
            options={addValue ? imageOverrideValues.concat(addValue) : imageOverrideValues}
            onChange={e => handleImageSelect(e)}
            data-testid={dataTestid}
            tabIndex={0}
            ref={ref}
        />
    );
});