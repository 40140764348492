import React, { useCallback, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { BrandTilesSelector } from './BrandTilesSelector';
import { ThinkhausHeaderTitle } from '../Generator/ThinkhausHeaderTitle';
import { useGeneratorContext } from '../../context/GeneratorContext';
import { useAuth } from '../../context/AuthProvider';
import { supabaseClient } from "../../config/supabase-client"
import axios, { AxiosRequestConfig } from 'axios';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { handleRemoveUPC } from '../InternalSyndigoUpdate/InternalSyndigoUpdateFuncs';


export const BrandTilesUploadPage = () => {
    const {
        products, setProducts,
        URL,
        dropdownOptions, setDropdownOptions,
        resultsBrandLibrary, setResultsBrandLibrary,
        brandTile, setBrandTile,
        updateStatus, setUpdateStatus,
        publishStatus, setPublishStatus,
        setIsShouldFetchResults
    } = useGeneratorContext();
    const [newUpc, setNewUpc] = useState('');
    const [loading, setLoading] = useState(false);
    const [isBrandTilesLoading, setIsBrandTilesLoading] = useState(false);
    const toast = React.useRef(null);

    let { session, user } = useAuth();


    const fetchBrandTilesTable = useCallback(async () => {
        if (!session || !user) {
            console.error("User is not authenticated or session is missing");
            return;
        }

        const { data, error } = await supabaseClient
            .from("brand_tiles")
            .select("*");

        if (error) {
            console.error("Error fetching brand tiles:", error);
        } else {
            setDropdownOptions(data);
        }
    }, [session, setDropdownOptions, user]);

    useEffect(() => {
        if (!dropdownOptions.length) {
            fetchBrandTilesTable();
        }
    }, [dropdownOptions.length, fetchBrandTilesTable]); //Check if we need to get the Brand Tiles on Load

    const handleAddUPC = () => {
        if (newUpc.trim()) {
            setProducts([...products, { UPC: newUpc }]);
            setNewUpc(''); // Clear input field
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'UPC cannot be empty' });
        }
    };

    const handleRunUPCs = () => {
        if (!brandTile) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'A brand tile must be selected' });
            return;
        }


        confirmDialog({
            message: 'Are you sure you want to run these UPCs?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => runUPCs(),
        });
    };

    const runUPCs = async () => {
        setLoading(true);
        try {
            let formatProduct = {
                products: products.map(product => ({
                    "GTIN": product?.UPC,
                })),
                brandTile: brandTile
            };

            const config: AxiosRequestConfig = {
                headers: {
                    "Authorization": `Bearer ${session.access_token}`
                }
            };

            const response = await axios.post(URL + 'api/brand-tile-upload', formatProduct, config);

            if (response.status === 200 || response.status === 207) {
                const { successfulProducts = [], failedProducts = [] } = response.data.responseData;

                const combinedResults = [
                    ...successfulProducts.map(product => ({ ...product, result: 'Success' })),
                    ...failedProducts.map(product => ({ ...product, result: 'Failed' }))
                ];

                setResultsBrandLibrary(combinedResults);

                if (response.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'UPCs processed successfully!' });
                } else if (response.status === 207 && successfulProducts.length === 0) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to process UPCs.' });
                } else if (response.status === 207 && successfulProducts.length !== 0) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Some UPCs could not be updated.' });
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to process UPCs.' });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred.' });
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setProducts([]);
        setResultsBrandLibrary([]);
    };

    const upcTemplate = (rowData) => {

        const renderUploadStatus = (UPC) => {
            if (loading) {
                return <ProgressSpinner className='ml-2' style={{ width: '20px', height: '20px' }} strokeWidth="8" />;
            }
            if (resultsBrandLibrary.length > 0) {
                const productResult = resultsBrandLibrary.find((product) => product.GTIN === UPC);

                if (productResult) {
                    if (productResult.result === 'Success') {
                        return <Message data-testid='upload-success-message' className='ml-2' severity="success" text="Upload successful" />;
                    } else if (productResult.result === 'Failed') {
                        return (
                            <div>
                                <Panel className='ml-2 mr-2 max-w-20rem min-w-20rem custom-error-panel' header="Error" collapsed={false} toggleable>
                                    <p className="m-0 text-left" data-testid={`fileInState-error-text-${UPC}`} >
                                        {productResult.error || "Unknown error"}
                                    </p>
                                </Panel>
                            </div>
                        )
                    }
                }
            }
            return null;
        };

        return (
            <div className="flex align-items-center justify-content-between">
                <div className='flex align-items-center'>
                    <span className='mr-2'>{rowData.UPC}</span>
                    {renderUploadStatus(rowData.UPC)}
                </div>
                <>
                    <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-text"
                        onClick={() => handleRemoveUPC(rowData, products, setProducts, resultsBrandLibrary, setResultsBrandLibrary, updateStatus, setUpdateStatus, publishStatus, setPublishStatus, setIsShouldFetchResults, setIsBrandTilesLoading)}
                        data-cy-removeupc
                    />
                </>

            </div>
        );
    };

    return (
        <div className="container min-w-screen surface-ground min-h-screen mx-auto">
            <div className="container w-11 min-h-screen mx-auto">
                <Toast ref={toast} />

                <div className="flex justify-content-between align-items-center mb-5">
                    <ThinkhausHeaderTitle>Brand-Tiles Uploader</ThinkhausHeaderTitle>
                </div>

                <div className="card p-4 border-1 surface-card">
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center mb-4">
                        <div className="flex-grow-1 mr-3">
                            <InputText
                                id="newUpc"
                                value={newUpc}
                                onChange={(e) => setNewUpc(e.target.value)}
                                placeholder="Enter a UPC"
                                className="p-inputtext-sm w-full"
                            />
                        </div>
                        <Button
                            label="Add UPC"
                            icon="pi pi-plus"
                            onClick={handleAddUPC}
                            className="p-button-rounded p-button-primary mt-3 sm:mt-0"
                        />
                    </div>

                    <div className="mb-4">
                        <DataTable
                            value={products}
                            emptyMessage="Please upload an excel file in Generator to add UPCs or insert manually here."
                            className="p-datatable-sm"
                            scrollable
                            scrollHeight="200px"
                            style={{ maxWidth: '100%' }}
                        >
                            <Column field="upc" header="UPC" body={upcTemplate} />
                        </DataTable>
                    </div>

                    <div className="flex justify-content-between align-items-center mb-4">
                        <BrandTilesSelector
                            brandTile={brandTile}
                            setBrandTile={setBrandTile}
                        />
                    </div>

                    <div className="flex justify-content-end align-items-center mt-4">
                        <Button
                            label="Run UPCs"
                            icon="pi pi-play"
                            className="p-button-rounded p-button-success mr-3"
                            onClick={handleRunUPCs}
                            disabled={loading || products.length === 0}
                        />
                        <Button
                            label="Clear"
                            icon="pi pi-times"
                            className="p-button-rounded p-button-secondary"
                            onClick={handleClear}
                            disabled={loading}
                            severity='danger'
                        />
                    </div>

                </div>

                <ConfirmDialog />
            </div>
        </div>
    );

};
